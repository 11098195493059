 export const bulb = [
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725372/BULB/13_c5vonk.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725367/BULB/14_zqv6qg.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725365/BULB/12_nulufb.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725362/BULB/11_yylrxb.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725361/BULB/10_roqkoe.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725359/BULB/9_hrwrhh.png',
]
export const concield = [
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713425157/CONCIELD/SUP002_earqry.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713425154/CONCIELD/SUP001_ka2cs3.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713425153/CONCIELD/SPOT001_imruss.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713425153/CONCIELD/SF002_rzcs3y.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713425151/CONCIELD/SF001_v2exel.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713425150/CONCIELD/DL0026_uq0ybd.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713425149/CONCIELD/DL0025_whhthx.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713425148/CONCIELD/DL0016_m1y837.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713425147/CONCIELD/DL0015_kb8zrk.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713425146/CONCIELD/DL002_fmdfsg.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713425145/CONCIELD/DL001_dtkqrx.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713425144/CONCIELD/DEEP003_ms3zhu.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713425143/CONCIELD/DEEP002_qm2hkd.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713425142/CONCIELD/DEEP001_r9g0rc.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713425142/CONCIELD/CN0011_uo3n0o.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713425140/CONCIELD/CN009_eikfd9.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713425139/CONCIELD/CN006_luekt7.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713425139/CONCIELD/CN007_yo9vsb.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713425140/CONCIELD/CN008_ce1hlb.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713425140/CONCIELD/CN001_xvbwuw.png',

]
export const adeptor = [
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712726922/DOCTOR/11_hig3mc.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712726918/DOCTOR/10_vnwqfg.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712726913/DOCTOR/9_jdnpwp.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712726908/DOCTOR/8_kcgq9r.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712726904/DOCTOR/7_gu506y.png',
]
export const fancyWall = [
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713426441/FANCY%20WALL/WhatsApp_Image_2024-04-18_at_1.12.55_PM-removebg-preview_rpo3jx.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712726792/FANCY%20WALL/2_swiwuz.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712726789/FANCY%20WALL/1_bimnjp.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712726135/FANCY%20WALL/WhatsApp_Image_2024-03-07_at_11.24.13_AM-removebg-preview_jheaeg.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712726132/FANCY%20WALL/WILSON.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725704/FANCY%20WALL/SIMPLY.jpg',

]
export const hanging = [
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713428463/HANGING/EUIRHG_SUINDFIHUISDFG-removebg-preview_mgxlxu.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725707/HANGING/MOTIF.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725620/HANGING/WhatsApp_Image_2024-03-16_at_11.56.22_AM-removebg-preview_qjbnyj.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725617/HANGING/WhatsApp_Image_2024-03-14_at_9.49.21_AM-removebg-preview_muy1om.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725615/HANGING/WhatsApp_Image_2024-03-07_at_10.30.12_AM-removebg-preview_rjmnmz.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725614/HANGING/WhatsApp_Image_2024-03-07_at_10.30.09_AM-removebg-preview_aixbx2.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725609/HANGING/WhatsApp_Image_2024-03-06_at_3.41.01_PM-removebg-preview_iskhh7.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725608/HANGING/WhatsApp_Image_2024-03-06_at_3.40.56_PM-removebg-preview_1_fgusm3.png',

]
export const gate = [
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725428/GATE/30_sevcni.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725434/GATE/32_jfpwpf.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725436/GATE/33_dupv6z.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725439/GATE/34_cq08cm.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725421/GATE/27_cxu9zx.jpg',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725449/GATE/38_cgggxu.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725445/GATE/37_x6xfcj.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725425/GATE/29_r4edud.jpg',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725422/GATE/28_svojuz.jpg',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713593401/GATE/43_rpnyxl.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725461/GATE/44_ali2wf.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725458/GATE/42_loybyq.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725440/GATE/35_y4wfye.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725442/GATE/36_gzjipw.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725456/GATE/41_rdympv.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725455/GATE/40_i3bi9y.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725451/GATE/39_xqmybf.png',

]
export const jhummer = [
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712726845/JHUMMER/14_y2z5q9.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712726840/JHUMMER/13_ukp5gc.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712726836/JHUMMER/12_vuvsmm.png',

]
export const strip = [
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725285/STRIP/RED-removebg-preview_fqxi31.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725280/STRIP/12-removebg-preview_1_ug3tgn.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725278/STRIP/5-removebg-preview_u8bmvh.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725278/STRIP/4-removebg-preview_hi329e.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725275/STRIP/2-removebg-preview_vs6ew6.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725275/STRIP/1-removebg-preview_tuokof.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713599008/STRIP/8_xsh7ny.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713599008/STRIP/7_ub6kbw.png',

]
export const wall = [
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713965346/WALL/LED_Wall_Light_Outdoor_Waterproof_Modern_Nordic_Style_Indoor_Wall_Lamps_Living_Room_Porch_Garden_Lamp_2W_4W_6W_8W_12W_NR-69_-_White___8W___Warm_white_w6ohxk.jpg',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713965346/WALL/Avivah_Wandleuchte_Wei%C3%9F_6_6__x_3_1__x_1_6____16_8cm_x_8cm_x_4cm_-_6W-Nat%C3%BCrliches_Wei%C3%9F_4000_K_nbgsvd.jpg',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713965346/WALL/510eTxOYTfL._AC_UF894_1000_QL80__ttugx7.jpg',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713965345/WALL/COB_LED_DOWN_LIGHT_5W_z8ggmu.jpg',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713965345/WALL/Avivah_Wandleuchte_Wei%C3%9F_5_6_cm_x_7_7_cm_x_3_4_cm_2_W-Nat%C3%BCrliches_Wei%C3%9F_4000_K_b2fgvg.jpg',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713965345/WALL/3W_6W_LED_Aluminum_Wall_Lamp_Porch_Light_Wall_Sconce_Square_Outdoor_Waterproof_Wall_Light_Garden_Lights_Modern_wall_lights_BL22_-_Warm_White___Square_6W___Gray_vr2bpc.jpg',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713965345/WALL/Avivah_Wandleuchte_Schwarz_6_6_x_3_1_x_1_6_Zoll___16_8_cm_x_8_cm_x_4_cm_6_W-Nat%C3%BCrliches_Wei%C3%9F_4000_K_blfqxp.jpg',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713965345/WALL/Avivah_Wandleuchte_Wei%C3%9F_12_cm_x_8_cm_x_4_cm_4_W-Warmwei%C3%9F_2700_3500_K_yoe3tt.jpg',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1713965346/WALL/WL2290-1-removebg-preview_vw5xjz.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725566/WALL/WhatsApp_Image_2024-03-02_at_6.36.59_PM-removebg-preview_u0lrxk.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725561/WALL/WhatsApp_Image_2024-03-01_at_4.58.54_PM-removebg-preview_ecwrio.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725544/WALL/WhatsApp_Image_2024-03-01_at_2.07.15_PM-removebg-preview_1_dzvupc.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725540/WALL/WhatsApp_Image_2024-02-08_at_12.15.04_PM__1_-removebg-preview_ododam.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725535/WALL/WhatsApp_Image_2024-02-06_at_9.52.02_AM-removebg-preview_qtywdv.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725532/WALL/WhatsApp_Image_2024-02-06_at_9.52.02_AM__1_-removebg-preview_f5wnyx.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725528/WALL/WhatsApp_Image_2024-03-02_at_6.07.59_PM_oejozo.jpg',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725526/WALL/WhatsApp_Image_2024-03-02_at_6.06.17_PM_quwtkn.jpg',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725515/WALL/980_nqxlsw.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725519/WALL/567889_ydqoxw.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725521/WALL/Final_ledexa_catalogue_2024-3.pdf-removebg-preview_kdsj0n.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725575/WALL/WhatsApp_Image_2024-03-22_at_4.34.29_PM-removebg-preview_qonian.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725574/WALL/WhatsApp_Image_2024-03-22_at_4.34.09_PM-removebg-preview_h4yeeu.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712727707/WALL/YO_azwgey.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712727695/WALL/654_mbwknj.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712727704/WALL/WhatsApp_Image_2024-03-01_at_4.06.48_PM__3_-removebg-preview_cyhyhc.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725559/WALL/WhatsApp_Image_2024-03-01_at_4.06.48_PM-removebg-preview_ywhfgc.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725568/WALL/WhatsApp_Image_2024-03-21_at_4.33.48_PM__1_-removebg-preview_i8k7p5.png',
    'https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725564/WALL/WhatsApp_Image_2024-03-01_at_5.06.16_PM-removebg-preview_ywgl65.png',
]